import 'magnific-popup';

$(function () {

  $('.popup-player').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade01',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
      markup: '<div class="mfp-iframe-scaler">' +
        '<div class="mfp-close"></div>' +
        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
        '</div>',

      srcAction: 'iframe_src',
    }
  });
});

