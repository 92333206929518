import lottieWeb from "lottie-web";
const container = document.getElementById('lottie_logoAnime'); //アニメーションを格納するDOM要素

lottieWeb.loadAnimation({
  container: container,// アニメーションを格納するDOM要素
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '../content/wp-content/themes/ashimoto/assets/json/data.json' // JSONファイルのパス
});
