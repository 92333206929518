import 'jquery-inview';
$('.inview').on('inview', function () {
  $(this).addClass('fadeInUp');
});
$('.inviewL').on('inview', function () {
  $(this).addClass('fadeInLeft');
});
$('.inviewR').on('inview', function () {
  $(this).addClass('fadeInRight');
});
$('.inviewZ').on('inview', function () {
  $(this).addClass('fadeInUpZoom');
});
$('.inviewF').on('inview', function () {
  $(this).addClass('fadeIn');
});
$('.inviewAnime').on('inview', function () {
  $(this).addClass('is-animated');
});
$('.inviewImg').on('inview', function () {
  $(this).addClass('imgZoomDown');
});
$('.inviewScale').on('inview', function () {
  $(this).addClass('scaleIn');
});
$('.inviewScale2').on('inview', function () {
  $(this).addClass('scaleIn2');
});
$('.inviewPoyon').on('inview', function () {
  $(this).addClass('poyon');
});
$('.inviewClass').on('inview', function () {
  $(this).addClass('is-view');
});
