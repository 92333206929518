(function () {
  const openbtns = document.querySelectorAll('.modal__open');
  const closebtns = document.querySelectorAll('.modal__close');
  const wrapper = document.querySelector('.wrapper');

  const modalOpen = (btn) => {
    modalClose();
    const modalId = btn.dataset.modal;
    const modal = document.querySelector(`.modal[data-modal="${modalId}"]`);
    modal.insertAdjacentHTML('beforeend', '<i class="modal__bg"></i>');
    modal.classList.add('is-open');
    const top = window.pageYOffset;
    wrapper.classList.add('is-modalopen');
    wrapper.style.top = `-${top}px`;
    const modalbg = document.querySelector('.modal__bg');
    modalbg.addEventListener('click', () => {
      modalClose();
    });
  }

  const modalClose = () => {
    const modal = document.querySelector(`.modal.is-open`);
    if (modal == null) { return false; }
    const getY = (callback) => {
      modal.classList.remove('is-open');
      const modalbg = document.querySelector('.modal__bg');
      const top = wrapper.style.top;
      const y = top.slice(1, -2);
      wrapper.style.top = '';
      wrapper.classList.remove('is-modalopen');
      modalbg.parentNode.removeChild(modalbg);
      callback(y);
    }
    getY((y) => {
      window.scrollTo(0, y);
    });
  }

  openbtns.forEach(openbtn => {
    openbtn.addEventListener('click', (event) => {
      const btn = event.currentTarget;
      modalOpen(btn);
    });
  });

  closebtns.forEach(closebtn => {
    closebtn.addEventListener('click', () => {
      modalClose();
    });
  });

})();
