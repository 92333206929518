$(function () {
  var $elem = $('.posSwitch');
  var replaceWidth = 769;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.prependTo(posFlgA);
      } else {
        $this.prependTo(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});
