const timing = function () {
  $('.mv-sec').find('figure').addClass('is-load');
};
const timing2 = function () {
  $('.mv-sec').find('.mask').addClass('is-load');
  $('.mv-sec').find('.catch p').addClass('is-animated');
};
setTimeout(timing, 300);
setTimeout(timing2, 500);

