import 'slick-carousel';
$('.top-works-slides').slick({
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4000,
  speed: 500,
  easing: 'swing',
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
});



const formConfirm = $('.confirm-btn');
const formBack = $('.back-btn');
formConfirm.find('input').mouseenter(function () {
  $(this).parent().addClass('is-mouseon');
});
formConfirm.find('input').mouseleave(function () {
  $(this).parent().removeClass('is-mouseon');
});
formBack.find('input').mouseenter(function () {
  $(this).parent().addClass('is-mouseon');
});
formBack.find('input').mouseleave(function () {
  $(this).parent().removeClass('is-mouseon');
});
