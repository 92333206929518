import Lenis from '@studio-freight/lenis';
import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

const lenis = new Lenis({
  lerp: 0.1,
});

lenis.on("scroll", Scrolltrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1100);
});

// Images parallax
gsap.utils.toArray(".js-parallax").forEach((parallaxBoxes) => {
  const parallaxImages = parallaxBoxes.querySelector(".mv-sec");

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: parallaxBoxes,
      scrub: true,
      pin: false,
      // markers: true,
    },
  });

  tl.fromTo(
    parallaxImages,
    {
      yPercent: -20,
      ease: "none",
    },
    {
      yPercent: 20,
      ease: "none",
    }
  );
});


//Lenis
// const lenis = new Lenis({
//   lerp: 0.1,
// });

// function raf(time) {
//   lenis.raf(time);
//   requestAnimationFrame(raf);
// }

// requestAnimationFrame(raf);






//gsap
const body = $('body');
const main = $('main');
const navPoint = $('.about-sec');
const missionBox = $('.mission-sec');
const missionTitle = $('.mission-title');
const missionBg = $('.mission-bg');
const missionMessage = $('.mission-message');
const valueBox = $('.value-box');

let mm = gsap.matchMedia();

let menuFlg = false;

mm.add("(min-width: 769px)", () => {
  gsap.to(missionTitle, {
    scrollTrigger: {
      trigger: missionTitle,
      start: 'top top',
      end: '+=100%',
      pin: true,
      //markers: true,
    }
  });
});
gsap.to(main, {
  scrollTrigger: {
    trigger: navPoint,
    start: 'top top',
    //markers: true,
    toggleActions: 'play none none reverse',
    onEnter: () => {
      body.addClass('nav-on');
    },
    onLeave: () => {
      //head.removeClass('fix');
    },
    onEnterBack: () => {
      body.addClass('nav-on');
    },
    onLeaveBack: () => {
      body.removeClass('nav-on');
      $('.main-menu').removeClass('active');
      $('.menu-trigger').removeClass('active');
      if (menuFlg == true) {
        gsap.fromTo(
          ".sheet",
          { autoAlpha: 1, },
          { autoAlpha: 0, }
        );
        menuFlg = false;
      }
    },
  }
});
gsap.to(missionBg, {
  scrollTrigger: {
    trigger: missionBox,
    start: 'top top',
    //markers: true,
    toggleActions: 'play none none reverse',
    onEnter: () => {
      missionBg.addClass('fix');
    },
    onLeave: () => {
      missionBg.removeClass('fix');
    },
    onEnterBack: () => {
      missionBg.addClass('fix');
    },
    onLeaveBack: () => {
      missionBg.removeClass('fix');
    },
  }
});

const valueTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".value-sec",
    start: "center center",
    end: "+=1500",
    scrub: true,
    pin: true,
    //markers: true,
  },
});
valueTl.fromTo(
  ".value__1",
  {
    opacity: 0,
    scale: 1.3,
    x: '-80',
  },
  { opacity: 1, scale: 1, x: 0, ease: "Power4.out", duration: 0.2 }, 0

);
valueTl.fromTo(
  ".value__2",
  {
    opacity: 0,
    scale: 1.3,
    x: '-80',
  },
  { opacity: 1, scale: 1, x: 0, ease: "Power4.out", duration: 0.2 }, 0.3333

);
valueTl.fromTo(
  ".value__3",
  {
    opacity: 0,
    scale: 1.3,
    x: '-80',
  },
  { opacity: 1, scale: 1, x: 0, ease: "Power4.out", duration: 0.2 }, 0.6666

);


//メニュー挙動

$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.parent().parent().next();
  $tar.stop().toggleClass('active');
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (menuFlg == false) {
    gsap.fromTo(
      ".sheet", // アニメーションさせる要素
      { autoAlpha: 0, },
      { autoAlpha: 1, }
    );
    menuFlg = true;
  } else {
    gsap.fromTo(
      ".sheet", // アニメーションさせる要素
      { autoAlpha: 1, },
      { autoAlpha: 0, }
    );

    menuFlg = false;
  };

  return false;
});

$(document).on('click', '.sheet', function () {
  $('.main-menu').stop().removeClass('active');
  $('.menu-btn').find('.menu-trigger').stop().removeClass('active');
  gsap.fromTo(
    ".sheet", // アニメーションさせる要素
    { autoAlpha: 1, },
    { autoAlpha: 0, }
  );
  menuFlg = false;
});
